/* Features list related styles */
.feature-list {
    border-top: 1px solid #ccc;
    padding-top: 10px;
}

.feature-item {
    padding: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.feature-item:hover {
    background-color: #eaeaea;
}

.filter-input {
    display: flex;
    align-items: center;
    gap: 10px;
}

/* OpenLayers popup style */
.ol-popup {
    position: absolute;
    background-color: white;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    bottom: 12px;
    left: -50px;
    min-width: 100px;
    text-align: center;
    z-index: 1000;
}
/* OpenLayers popup style */


.WebMercatorMap {
    /* display: flex;   */
    height: 85vh;  
    overflow: hidden;
    margin: 0;
    padding: 0;
}


/* Considering the sidebar's box-shadow, give the map some shadow for depth */
.InteractiveMap {
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    flex-grow: 1; 
    position: relative;  /* might be needed depending on how the map is rendered */
    /* display: block */
}
/* .WebMercatorMap, .InteractiveMap {
    margin: 0;
    padding: 0;
} */


